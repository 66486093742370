import ProfileElements from '@components/Profile'
import Controls from './Controls'
import Forms from './Forms'
import Sidebar from './Sidebar'
import UiElements from './UiElements'
import Pagination from './Pagination'
import News from './News'
import Exchange from './Exchange'
import Reviews from './Reviews'
import CookiesInfo from './CookiesInfo'
import GoOnTop from './GoOnTop'
import PageNotFound from './PageNotFound'

export default function installComponents(Vue) {
  Vue.use(Controls)
  Vue.use(Sidebar)
  Vue.use(UiElements)
  Vue.use(News)
  Vue.use(Pagination)
  Vue.use(Exchange)
  Vue.use(Forms)
  Vue.use(ProfileElements)
  Vue.use(Reviews)
  Vue.use(CookiesInfo)
  Vue.use(GoOnTop)
  Vue.use(PageNotFound)
}
