import { createI18n } from '@/i18n'
import { createRouter } from '@/js/router'

export function createApp({ Vue, VueRouter, I18n }) {
  const i18n = createI18n({ Vue, I18n })
  const router = createRouter({ Vue, VueRouter })
  const app = new Vue({
    i18n,
    router,
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
      }
    },
  })

  return { app, router, i18n }
}
