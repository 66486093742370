const Exchange = () => import('./Exchange')
const StepFirst = () => import('./StepFirst')
const StepTwo = () => import('./StepTwo')
const TransactionInfo = () => import('./TransactionInfo')

export default function installComponents(Vue) {
  Vue.component('Exchange', Exchange)
  Vue.component('StepFirst', StepFirst)
  Vue.component('StepTwo', StepTwo)
  Vue.component('TransactionInfo', TransactionInfo)
}
