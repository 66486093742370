const UiSlider = () => import('./UiSlider')
const ModalTrigger = () => import('./ModalTrigger')
const UiTabs = () => import('./UiTabs')
const UiAccordion = () => import('./UiAccordion')
const UiClickOutside = () => import('./UiClickOutside')
// const UiRecaptcha = () => import('./UiRecaptcha')
const Renderer = () => import('./Renderer')
const UiReadMore = () => import('./UiReadMore')

export default function installComponents(Vue) {
  Vue.component('UiSlider', UiSlider)
  Vue.component('ModalTrigger', ModalTrigger)
  Vue.component('UiTabs', UiTabs)
  Vue.component('UiAccordion', UiAccordion)
  Vue.component('UiClickOutside', UiClickOutside)
  // Vue.component('UiRecaptcha', UiRecaptcha)
  Vue.component('Renderer', Renderer)
  Vue.component('UiReadMore', UiReadMore)
}
