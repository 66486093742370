<template lang="pug">
  include ./../../../templates/mixins.pug

  label(
    :for='id',
    v-bem.label='{"static": true}'
  ) {{ inputLabel }}
    span(
      v-if='isRequired'
      v-bem.is-required=''
    ) *
</template>

<script>
export default {
  name: 'BoundLabel',
  block: 'd-control-input',
  props: {
    id: {
      type: String,
      required: false,
    },
    value: {},
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
  },
}
</script>
