import './public-path'

import Vue from 'vue'
import I18n from 'vue-i18n'
import VueRouter from 'vue-router'

import { install } from '@/install'
import { createApp } from '@/app'

import '@app/js/directives'
import '@app/js/validation'

import './styles/index.sass'

Vue.config.productionTip = false

Vue.use(install)

const { app } = createApp({
  Vue, VueRouter, I18n,
})

app.$mount('#app')

export { app }
