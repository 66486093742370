import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { app } from '@app/main'

Object.entries(rules).forEach(([rule, validation]) => {
  extend(rule, {
    ...validation,
  })
})

extend('yandex', {
  validate(value) {
    const yandexPhoneLength = 11
    const yandexCardLength = 21
    const length = value.replace(/\D/g, '').toString()
    return yandexPhoneLength <= length.length && yandexCardLength >= length.length
  },
  message(value, args) {
    return `${app._('Пример: ')} ${args[0]}`
  },
})

extend('wallet', {
  validate(value) {
    const CHARS = /^[A-Za-z0-9]*$/
    return CHARS.test(value)
  },
  message(value, args) {
    return `${app._('Пример: ')} ${args[0]}`
  },
})

extend('qiwi', {
  validate(value) {
    const rusCardLength = 11
    const cardLength = 14
    const length = value.replace(/\D/g, '').toString()
    return rusCardLength <= length.length && cardLength >= length.length
  },
  message(value, args) {
    return `${app._('Пример: ')} ${args[0]}`
  },
})

extend('card', {
  validate(value) {
    const cardLength = 16
    return cardLength === value.replace(/\D/g, '').toString().length
  },
  message(value, args) {
    return `${app._('Пример: ')} ${args[0]}`
  },
})

extend('phoneNumber', {
  validate(value) {
    const phoneLength = 12
    const rusPhoneLength = 11
    return phoneLength === value.replace(/\D/g, '').toString().length || rusPhoneLength === value.replace(/\D/g, '').toString().length
  },
  message() {
    return app._('Пример: 380660000000, 79160000000.')
  },
})

extend('required', {
  ...rules.required,
  message() {
    return app._('Это поле обязательное.')
  },
})

extend('email', {
  ...rules.email,
  message() {
    return app._('Поле должно быть электронным адресом.')
  },
})

extend('min_value', {
  ...rules.min_value,
  message() {
    return app._('Сумма должна быть больше 0.')
  },
})

extend('min', {
  ...rules.min,
  message(value, sec) {
    return app._('Количество символов должно быть не меньше ') + sec.length
  },
})

extend('max', {
  ...rules.max,
  message(value, sec) {
    return app._('Количество символов не должно превышать') + sec.length
  },
})

extend('confirmed', {
  ...rules.confirmed,
  message() {
    return app._('Значения не совпадают')
  },
})
