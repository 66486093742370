const QuestionForm = () => import('./QuestionForm')
const Feedback = () => import('./Feedback')
const ReviewsForm = () => import('./ReviewsForm')
const ConfirmPassword = () => import('./ConfirmPassword')
const Logout = () => import('./Logout')
const AuthForm = () => import('./AuthForm')

export default function installComponents(Vue) {
  Vue.component('QuestionForm', QuestionForm)
  Vue.component('Feedback', Feedback)
  Vue.component('ReviewsForm', ReviewsForm)
  Vue.component('ConfirmPassword', ConfirmPassword)
  Vue.component('Logout', Logout)
  Vue.component('AuthForm', AuthForm)
}
