import * as LINKS from '@router/Profile/consts'

const PersonalDataComponent = () => import('@components/Profile/PersonalData')
const BonusProgramComponent = () => import('@components/Profile/BonusProgram')
const MyAccountsComponent = () => import('@components/Profile/MyAccounts')
const ExchangeHistoryComponent = () => import('@components/Profile/ExchangeHistory')
const AffiliateProgramComponent = () => import('@components/Profile/AffiliateProgram')
const WithdrawComponent = () => import('@components/Profile/Withdraw')

export const routes = [
  {
    path: LINKS.PROFILE,
    component: PersonalDataComponent,
    name: 'profile',
  },
  {
    path: LINKS.BONUS,
    component: BonusProgramComponent,
    name: 'bonus',
  },
  {
    path: LINKS.ACCOUNTS,
    component: MyAccountsComponent,
    name: 'accounts',
  },
  {
    path: LINKS.HISTORY,
    component: ExchangeHistoryComponent,
    name: 'history',
  },
  {
    path: LINKS.AFFILIATE,
    component: AffiliateProgramComponent,
    name: 'affiliate',
  },
  {
    path: LINKS.WITHDRAW,
    component: WithdrawComponent,
    name: 'withdraw',
  },
]
