const ProfileController = () => import('@components/Profile/Controller')
const ProfileNavbar = () => import('@components/Profile/Navbar')
const PersonalData = () => import('@components/Profile/PersonalData')
const BonusProgram = () => import('@components/Profile/BonusProgram')
const MyAccounts = () => import('@components/Profile/MyAccounts')
const ExchangeHistory = () => import('@components/Profile/ExchangeHistory')
const AffiliateProgram = () => import('@components/Profile/AffiliateProgram')
const Withdraw = () => import('@components/Profile/Withdraw')

export default function installComponents(Vue) {
  Vue.component('ProfileController', ProfileController)
  Vue.component('ProfileNavbar', ProfileNavbar)
  Vue.component('PersonalData', PersonalData)
  Vue.component('BonusProgram', BonusProgram)
  Vue.component('MyAccounts', MyAccounts)
  Vue.component('ExchangeHistory', ExchangeHistory)
  Vue.component('AffiliateProgram', AffiliateProgram)
  Vue.component('Withdraw', Withdraw)
}
