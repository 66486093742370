import VueRouter from 'vue-router'
import Vue from 'vue'

import { routes as ProfileRoutes } from '@router/Profile/routes'
import { notFound as PageNotFound } from '@router/NotFound/notFound'

Vue.use(VueRouter)

const scrollBehavior = () => {
  const timeout = 500
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ x: 0, y: 0 })
      reject()
    }, timeout)
  })
}

export const createRouter = () => new VueRouter({
  mode: 'history',
  scrollBehavior,
  routes: [
    ...ProfileRoutes,
    ...PageNotFound,
  ],
})
