<template lang="pug">
  include ./../../../templates/mixins.pug
  label(
    v-bem.label='{"static": true}'
    :class="{'d-control-input__label--active': isValue }"
    :for='id',
  ) {{ inputLabel }}
    slot
    span(
      v-if='isRequired'
      v-bem.is_required=''
    ) *
</template>

<script>
export default {
  name: 'BoundLabel',
  block: 'd-control-input',
  props: {
    labelText: {},
    id: {
      type: String,
      required: false,
    },
    value: {},
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
  },
  computed: {
    isValue() {
      if (Array.isArray(this.value)) {
        if (this.value.length) {
          return true
        }
      } else if (this.value) {
        return true
      }
      return false
    },
  },
}
</script>
